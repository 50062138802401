import { render, staticRenderFns } from "./ModalChangeIssRetain.vue?vue&type=template&id=501954bf&scoped=true"
import script from "./ModalChangeIssRetain.vue?vue&type=script&setup=true&lang=js"
export * from "./ModalChangeIssRetain.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501954bf",
  null
  
)

export default component.exports