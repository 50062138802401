<!-- Versão: 1.0 -->
<!-- Exemplo de uso: src/views/Modules/Financial/Allotments/Invoice/Shared/_ModalIndividual.vue -->

<template>
  <div
    class="switch"
    :class="{ 'switch--on': value, 'switch--disabled': disabled }"
    @click="toggle"
    :aria-disabled="disabled"
  >
    <div class="switch__circle"></div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

// Definição das props
const props = defineProps({
  value: {
    type: [Boolean, Number],
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

/** Eventos emitidos */
const emit = defineEmits([
  'input',
  'update:items',
]);

// Função para alternar o valor
function toggle() {
  if (props.disabled) return;
  emit("input", !props.value)
}
</script>

<style scoped>
.switch {
  width: 55px;
  height: 25px;
  background-color: #fa7272; /* Vermelho */
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switch.switch--on {
  background-color: #96e096; /* Verde */
  justify-content: end;
}

.switch.switch--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.switch__circle {
  width: 17px;
  height: 17px;
  background-color: #fff5dc; /* Cor clara */
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}
</style>
