<template>
  <div
    class="app-search-bar"
  >
    <!-- Central -->
    <!-- 100% até 768px -->
    <!-- 35% de 768px até 992px -->
    <!-- auto à partir de 992px -->
    <nav
      v-if="props.showCompanyPlants"
      class="plant-spot item"
      :class="{'most-available': !(hasMoreThanOneRangeType || hasRangeValue)}"
    >
      <div
        class="filter-option-item"
      >
        <AppSelect
          style="width: 100%;"
          v-model="localSearchBarFilter.company_plant_selected"
          icon="/img/icons/icons8/ios/chemical-plant.png"
          :items="companyPlants"
          :isSearchable="true"
          :clearable="allowClearCompanyPlantFilter"
          @onSelectItemClick="onSearchClick"
          @onClearSelectionClick="onSearchClick"
          @input="setCompanyPlantIdInStorage()"
        />
      </div>
    </nav>
    <!-- Datepicker -->
    <!-- 100% até 768px -->
    <!-- 65% de 768px até 992px -->
    <!-- auto à partir de 992px -->
    <nav
      v-if="hasMoreThanOneRangeType || hasRangeValue"
      class="datepicker-spot item"
      :class="{'most-available': !props.showCompanyPlants}"
    >
      <div
        class="filter-option-item"
      >
        <div
          class="date-selector"
        >
          <AppSelect
            style="width: 100%"
            v-if="hasMoreThanOneRangeType"
            v-model="localSearchBarFilter.range.selected"
            icon="/img/icons/icons8/ios/empty_calendar_gray.png"
            :items.sync="localSearchBarFilter.range.items"
            @onSelectItemClick="onSearchClick"
            @onClearSelectionClick="onSearchClick"
          />
          <div
            style="width: 100%;"
          >
            <input-date-picker
              v-if="hasRangeValue"
              @handleFilterDate="handleFilterDate"
              :default-range="defaultRange"
              :isRange="props.rangeParams.is_range"
              :onlyFilterMonth="props.rangeParams.only_filter_month"
              :hideAllStyles="true"
              :disableBoxShadow="true"
              :disabled="null"
              :filterable="props.rangeParams.bottom_filter"
              :disable-max-date="null"
              size="md"
              visibility="focus"
              ref="datePicker1"
            />
          </div>
        </div>
      </div>
    </nav>
    <!-- Search Input / Filtros / Limpar -->
    <!-- 100% até 992px -->
    <!-- auto à partir de 992px -->
    <nav
      class="additional-input-spot item"
    >
      <div
        style="width: 100%; display: flex;"
      >
        <div
          v-if="localSearchBarFilter.custom_search_values"
          class="filter-option-item"
        >
          <div
            class="search-input"
          >
            <input
              v-model="localQuery"
              type="text"
              placeholder="Pesquisar"
              @keyup.enter="debounceSearch(250)"
              @input="debounceSearch()"
            />
            <i
              v-if="props.isLoading || isDebounceLoading"
              class="fas fa-circle-notch fa-spin"
              style="color: #b0b0b0;"
            />
            <img
              v-else
              @click.prevent="debounceSearch(250)"
              src="/img/icons/icons8/ios/search_gray.png"
              class="pointer"
              width="22"
            />
          </div>
        </div>
        <div
          v-if="!!slots.default"
          class="extra-filter-spot"
        >
          <div
            class="extra-filter-button"
          >
            <img
              src="/img/icons/icons8/ios/filter-solid_primary.png"
              class="pointer"
              @click.prevent="onToogleFilterClick"
              width="26"
            >
            <AppSearchBarFilter
              :show="showFilters"
              @handleClose="onToogleFilterClick"
              @handleSearch="onSearchClick"
              @handleClear="onClearClick"
            >
              <slot />
            </AppSearchBarFilter>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  defineEmits,
  defineProps,
  computed,
  useSlots,
  onBeforeMount,
} from "vue";
import {
  AppSelect,
  AppSearchBarFilter,
  initSearchBarFilterType,
  initSearchBarRangeParamsType,
  SearchBarFilterType,
  SearchBarRangeParamsType
} from '..';
import InputDatePicker from "@/components/InputDatePicker";
import { date } from "../../../helpers";
import store from "../../../store";
import Vue from "vue";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {SearchBarFilterType} searchBarFilter - Filtro do SearchBar
 * @property {SearchBarFilterType} showCompanyPlants - Exibir listagem de centrais
 * @property {boolean} allowClearCompanyPlantFilter - Permitir limpar filtro de centrais
 * @property {SearchBarRangeParamsType} rangeParams - Parâmetros referente ao período
 * @property {boolean} isLoading - Indica se o componente está em estado de carregamento
 */
/** @type {Props} */
const props = defineProps({
  searchBarFilter: {
    type: Object,
    default: () => initSearchBarFilterType(),
    required: true,
  },
  showCompanyPlants: {
    type: Boolean,
    default: false,
    required: false
  },
  allowClearCompanyPlantFilter: {
    type: Boolean,
    default: true,
    required: false
  },
  rangeParams: {
    type: Object,
    default: () => initSearchBarRangeParamsType(),
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
    required: false
  },
});


/** Eventos emitidos */
const emit = defineEmits([
  'update:searchBarFilter',
  "onSearchClick",
  "onToogleFilterClick",
  "onClearClick",
  "onFilterDate",
]);


/** @type {import("vue").Ref<SearchBarFilterType>} Filtro local de searchBarFilter */
const localSearchBarFilter = ref(props.searchBarFilter);

/** Mostrar/esconder opções de filtro */
const showFilters = ref(false);

/** Formato padrão de data */
const defaultFormatDate = date.FORMAT.ISO_8601;

/** Centrais */
const companyPlants = computed(() => store.getters["plant/activeShortItems"]);
const companyPlantIdKey = 'company_plant_id';

/** Referência de componente input-date-picker */
const datePicker1 = ref(null);

/** Tem lista de tipos de período informado */
const hasMoreThanOneRangeType = computed(() => {
  return (localSearchBarFilter.value.range?.items?.length ?? 0) > 1;
});

/** Loading de debounce do input */
const isDebounceLoading = ref(false);

/**
 * Padrão de período de datas
 * @type {import("vue").ComputedRef<String|{start: string, end: string}>} Filtro local de searchBarFilter
 */
const defaultRange = computed(() => {
  if (props.rangeParams.is_range) {
    return {start: localSearchBarFilter.value.range?.start, end: localSearchBarFilter.value.range?.end};
  }
  return localSearchBarFilter.value.range?.start;
});

/** Função para verificação de slots */
const slots = useSlots();

/** Verificar se existem valores preenchidos no período */
const hasRangeValue = computed(() => {
  return (Boolean) (localSearchBarFilter.value.range?.start && localSearchBarFilter.value.range?.end);
});

/** Armazenar consulta do componente */
const localQuery = ref('');

/** Pesquisa realizada com debounce */
const debounceSearch = debounce(onSearchClick, 1300);

/** Sincronização de localSearchBarFilter e searchBarFilter */
watch(() => props.searchBarFilter, (newValue) => {
  localSearchBarFilter.value = newValue;
  localQuery.value = newValue.custom_search_values.join(" ");
  if (hasRangeValue.value && datePicker1.value) {
    datePicker1.value.resetDate();
  }
}, { deep: true });
watch(localSearchBarFilter, (newValue) => emit('update:searchBarFilter', newValue));

/** Sincronização de localQuery (string) e localSearchBarFilter.custom_search_values (array) */
watch(localQuery, (newValue) => {
  if (!localSearchBarFilter.value.custom_search_values) {
    return;
  }
  localSearchBarFilter.value.custom_search_values = newValue ? newValue.split(' ') : [];
});

/**
 * Setar período em filtro local
 * @param {object} range
 * @param {string} range.start Data inicial (ISO_8601)
 * @param {string} range.end Data final (ISO_8601)
 */
function handleFilterDate(range) {
  if (props.rangeParams.is_range) {
    localSearchBarFilter.value.range.start = date.make(range.start).format(defaultFormatDate);
    localSearchBarFilter.value.range.end = date.make(range.end).format(defaultFormatDate);
  } else {
    localSearchBarFilter.value.range.start = date.make(range).format(defaultFormatDate);
    localSearchBarFilter.value.range.end = date.make(range).format(defaultFormatDate);
  }
  emit('onSearchClick');
  emit('onFilterDate');
}

function onSearchClick() {
  emit('onSearchClick');
}

function onToogleFilterClick() {
  showFilters.value = !showFilters.value
  emit('onToogleFilterClick', showFilters.value);
}

function onClearClick() {
  emit('onClearClick');
}

/**
 * Armazenar companyPlantId em Storage (Local e Session)
 */
function setCompanyPlantIdInStorage() {
  const value = localSearchBarFilter.value.company_plant_selected;
  if (value === null) {
    localStorage.removeItem(companyPlantIdKey);
    sessionStorage.removeItem(companyPlantIdKey);
    Vue.prototype.$filterable = '';
  } else {
    localStorage.setItem(companyPlantIdKey, value);
    sessionStorage.setItem(companyPlantIdKey, value);
    Vue.prototype.$filterable = value;
  }
}

function debounce(func, defaultWait) {
  let timeout;
  return function(wait, ...args) {
    clearTimeout(timeout);
    isDebounceLoading.value = true;
    timeout = setTimeout(() => {
      func.apply(this, args);
      isDebounceLoading.value = false;
    }, wait || defaultWait);
  };
};

onBeforeMount(async () => {
  // Garantir listagem de centrais
  const hasFetchPlants = props.showCompanyPlants && companyPlants.value.length === 0;
  if (hasFetchPlants) {
    await store.dispatch("plant/fetchItemsActive");
  }

  const companyPlantId = localStorage.getItem(companyPlantIdKey);
  const sessionCompanyPlantId = sessionStorage.getItem(companyPlantIdKey);

  Vue.prototype.$filterable = Number(companyPlantId) || Number(sessionCompanyPlantId) || '';
});
</script>

<style scoped>
.app-search-bar {
  display: flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  border: 1px solid #eeeeee;
  box-shadow: 0px 5px 5px 0px #0000000D;
  padding: 10px 0px 10px 0px;
  border-radius: 12px;
}

.date-selector {
  display: block;
  align-items: center;
  height: auto;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  width: 100%;
}

.extra-filter-spot {
  height: 36px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.filter-option-item {
  border-right: 1px solid #E8E8E8;
  height: auto;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.filter-option-item.clear-button {
  border-right: none;
  border-left: 1px solid #E8E8E8;
}

.search-input, .search-input input {
  width: 100%;
}

.search-input {
  display: inline-flex;
  align-items: center;
}

.search-input input, .search-input input:focus, .search-input input:focus-visible {
  border: none;
  height: 36px;
  box-shadow: none !important;
  outline: none;
  background: transparent;
}

.extra-filter-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
}

.clear-filters {
  height: 36px;
  display: flex;
  align-items: center;
}

.additional-input-spot .filter-option-item:first-child {
  width: 100%;
}

@media (min-width: 450px){
  .date-selector {
    display: inline-flex;
    height: 36px;
  }

  .filter-option-item {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .item {
    flex: 1 1 100%;
  }

  .plant-spot .filter-option-item, .datepicker-spot .filter-option-item {
    border: none;
    margin-bottom: 6px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .app-search-bar {
    flex-direction: row;
  }

  .plant-spot {
    flex: 0 0 35%;
  }

  .datepicker-spot {
    flex: 0 0 65%;
  }

  .most-available {
    flex: 0 0 100%;
  }

  .most-available .filter-option-item {
    border: none !important;
  }

  .plant-spot .filter-option-item {
    margin-bottom: 6px;
  }

  .datepicker-spot .filter-option-item {
    border: none;
    margin-bottom: 6px;
  }

  .additional-input-spot {
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .app-search-bar {
    width: fit-content;
  }
}
</style>
